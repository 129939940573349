import createApi from './auth';
import Service from './Service';

import { isProdVersion } from '@/utils/environment_controller';
import { StFeatureFlagFlags } from '@/plugins/rollout';

const version = isProdVersion()
  ? StFeatureFlagFlags.NotificationsSettingsServiceVersionProd.getValue()
  : StFeatureFlagFlags.NotificationsSettingsServiceVersionStaging.getValue();

const afterParser = (response) => response.data;

class NotificationsSettingsService extends Service {
  constructor(api) {
    super(api, { afterParser });
  }

  get() {
    return this.api.get({ url: '' }).then(this.afterParser);
  }

  save(params) {
    return this.api.put({ ...params, url: '' }).then(this.afterParser);
  }
}

const api = createApi(`realtime-notifications-settings/${version}/notification-settings`);

export default new NotificationsSettingsService(api);
