var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "product-roles pa-0 mt-6 fluid" },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c(
              "h3",
              [
                _c("StIcon", {
                  staticClass: "mr-3",
                  attrs: { "icon-name": "hexagon" }
                }),
                _c("span", { staticClass: "subtitle-1 font-weight-bold" }, [
                  _vm._v("\n          " + _vm._s(_vm.title) + "\n        ")
                ])
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pr-0 mr-0", attrs: { cols: "4" } },
            [_c("ProductTabSelection")],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pl-0 ml-0", attrs: { cols: "8" } },
            [
              _c("v-data-table", {
                staticClass: "product-roles__roles",
                attrs: {
                  items: _vm.productRoles,
                  "hide-default-header": "",
                  "hide-default-footer": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function(props) {
                      return [
                        _c("tr", [
                          _c("td", { staticClass: "body-2 text--gray-100" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(props.item.text) +
                                "\n            "
                            )
                          ]),
                          _c(
                            "td",
                            [
                              _c("v-switch", {
                                staticClass: "product-roles__item-switch ma-0",
                                attrs: { color: "primary" },
                                model: {
                                  value: props.item.isActive,
                                  callback: function($$v) {
                                    _vm.$set(props.item, "isActive", $$v)
                                  },
                                  expression: "props.item.isActive"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }