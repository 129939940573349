<template>
  <v-container
    class="notifications-settings"
    fluid
  >
    <h2 class="headline font-weight-bold mb-5">
      <StIcon
        icon-name="bell"
        class="mr-4"
      />
      {{ $t('NotificationsSettings.pageTitle') }}
    </h2>

    <StReloadAndLoaderWrapper
      class="notifications-settings__wrapper"
      :request-status="requestStatus"
      @reload="loadData"
    >
      <template v-slot:wrappedComponent>
        <v-form
          ref="form"
        >
          <div class="notifications-settings__email-wrapper">
            <v-switch
              v-model="emailEnabled"
              color="primary"
            />
            <span class="subtitle-1 ml-8 text--gray-100">{{ $t('NotificationsSettings.emailTip') }}</span>
          </div>
          <div class="subtitle-1 text--gray-300 mb-9">
            {{ user.email }}
          </div>

          <ProductRoles
            :title="$t('NotificationsSettings.notifications')"
            :roles="roles"
          />

          <SubmitBar
            :confirm-btn-text="$t('NotificationsSettings.submit')"
            :loading="isSaving"
            @on-submit-clicked="save"
          />
        </v-form>
      </template>
    </StReloadAndLoaderWrapper>
  </v-container>
</template>

<script>
import StIcon from '@stilingue/st-icon/StIcon';
import requestStatusMixin, { REQUEST_STATUS } from '@/mixins/request_status.mixin';
import StReloadAndLoaderWrapper from '@stilingue/st-reload-and-loader-wrapper';
import ProductRoles from '@/components/permissions/ProductRoles';
import SubmitBar from '@/components/permissions/SubmitBar';
import { mapState } from 'vuex';
import NotificationsSettingsService from '@/services/NotificationsSettingsService';

export default {
  name: 'NotificationsSettings',
  components: {
    StIcon,
    ProductRoles,
    SubmitBar,
    StReloadAndLoaderWrapper,
  },
  mixins: [requestStatusMixin],
  data() {
    return {
      requestStatus: REQUEST_STATUS.loading,
      emailEnabled: true,
      roles: {},
      isSaving: false,
    };
  },
  computed: {
    ...mapState('user', ['user']),
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.requestStatus = REQUEST_STATUS.loading;

      try {
        const data = await NotificationsSettingsService.get();

        this.requestStatus = REQUEST_STATUS.success;
        this.updateData(data);
      } catch {
        this.requestStatus = REQUEST_STATUS.error;
      }
    },
    updateData({ ticketSettings, generalSettings }) {
      const roles = Object.keys(ticketSettings).map((role) => ({
        text: this.$t(`NotificationsSettings.roles.${role}`),
        value: role,
        isActive: ticketSettings[role]
      }));

      this.emailEnabled = generalSettings.emailEnabled;
      this.roles = { tickets: [...roles] };
    },
    convertDataToSend() {
      const roles = {};
      this.roles.tickets.forEach((role) => {
        roles[role.value] = role.isActive;
      });

      return {
        generalSettings: {
          emailEnabled: this.emailEnabled,
        },
        ticketSettings: { ...roles },
      };
    },
    async save() {
      this.isSaving = true;

      try {
        await NotificationsSettingsService.save(this.convertDataToSend());
        this.$noty.success(this.$t('NotificationsSettings.saveSuccessFeedback'));
      } catch {
        this.$noty.error(this.$t('NotificationsSettings.saveErrorFeedback'));
      } finally {
        this.isSaving = false;
      }
    }
  }
};
</script>

<style lang="scss">
  .notifications-settings {
    &__wrapper {
      min-height: 300px;
    }

    &__email-wrapper {
      display: flex;
      align-items: center;
    }
  }
</style>
