<template>
  <div
    class="product-roles pa-0 mt-6 fluid"
  >
    <v-row>
      <v-col cols="12">
        <h3>
          <StIcon
            icon-name="hexagon"
            class="mr-3"
          />
          <span class="subtitle-1 font-weight-bold">
            {{ title }}
          </span>
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="4"
        class="pr-0 mr-0"
      >
        <ProductTabSelection />
      </v-col>

      <v-col
        cols="8"
        class="pl-0 ml-0"
      >
        <v-data-table
          class="product-roles__roles"
          :items="productRoles"
          hide-default-header
          hide-default-footer
        >
          <template v-slot:item="props">
            <tr>
              <td class="body-2 text--gray-100">
                {{ props.item.text }}
              </td>
              <td>
                <v-switch
                  v-model="props.item.isActive"
                  class="product-roles__item-switch ma-0"
                  color="primary"
                />
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProductTabSelection from './ProductTabSelection';
import StIcon from '@stilingue/st-icon/StIcon';

const INITIAL_PRODUCT = 'tickets';

export default {
  name: 'ProductRoles',
  components: {
    StIcon,
    ProductTabSelection
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    roles: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      productRoles: this.roles[INITIAL_PRODUCT]
    };
  }
};
</script>

<style lang="scss">
  .product-roles__roles {
    width: 100%;

    tr td {
      height: 35px !important;
    }

    tr td:last-child {
      width: 50px;
    }

    .v-data-table__wrapper {
      padding: 16px 0;
      min-height: 260px;
      background-color: var(--gray-600) !important;
    }
  }

  .product-roles__item-switch .v-input__control {
    height: 24px;
  }
</style>
