var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "notifications-settings", attrs: { fluid: "" } },
    [
      _c(
        "h2",
        { staticClass: "headline font-weight-bold mb-5" },
        [
          _c("StIcon", { staticClass: "mr-4", attrs: { "icon-name": "bell" } }),
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("NotificationsSettings.pageTitle")) +
              "\n  "
          )
        ],
        1
      ),
      _c("StReloadAndLoaderWrapper", {
        staticClass: "notifications-settings__wrapper",
        attrs: { "request-status": _vm.requestStatus },
        on: { reload: _vm.loadData },
        scopedSlots: _vm._u([
          {
            key: "wrappedComponent",
            fn: function() {
              return [
                _c(
                  "v-form",
                  { ref: "form" },
                  [
                    _c(
                      "div",
                      { staticClass: "notifications-settings__email-wrapper" },
                      [
                        _c("v-switch", {
                          attrs: { color: "primary" },
                          model: {
                            value: _vm.emailEnabled,
                            callback: function($$v) {
                              _vm.emailEnabled = $$v
                            },
                            expression: "emailEnabled"
                          }
                        }),
                        _c(
                          "span",
                          { staticClass: "subtitle-1 ml-8 text--gray-100" },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("NotificationsSettings.emailTip"))
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "subtitle-1 text--gray-300 mb-9" },
                      [
                        _vm._v(
                          "\n          " + _vm._s(_vm.user.email) + "\n        "
                        )
                      ]
                    ),
                    _c("ProductRoles", {
                      attrs: {
                        title: _vm.$t("NotificationsSettings.notifications"),
                        roles: _vm.roles
                      }
                    }),
                    _c("SubmitBar", {
                      attrs: {
                        "confirm-btn-text": _vm.$t(
                          "NotificationsSettings.submit"
                        ),
                        loading: _vm.isSaving
                      },
                      on: { "on-submit-clicked": _vm.save }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }